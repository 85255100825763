import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { Link } from "gatsby"

// markup
const NotFoundPage = () => {
  useEffect(() => {
    <Redirect to="/" />
  }, []);

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </>
  )
}

export default NotFoundPage;
